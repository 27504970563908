<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<mtipobulto-modals
			:modelName="modelName"
		></mtipobulto-modals>
	</div>
</template>

<script>
import mtipobultoActions from './MtipobultoActions';
import mtipobultoModals from './MtipobultoModals.vue';

export default {
	name: 'mtipobulto-grid',
	components: {
		'mtipobulto-modals': mtipobultoModals
	},
	data() {
		return {
			modelName: 'mtipobulto',
			actions: mtipobultoActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
